import styled from 'styled-components'
import {device, DeviceSize} from "../../../global.style";

export const ConfirmGuestComponentStyle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  background: var(--color-primary-2);
  border: 1px var(--color-primary) solid;
  padding: 15px 20px;
  justify-content: space-between;

  @media screen and (${device(DeviceSize.tablet)}){
    flex-direction: row;
  }
  
  .left {
    display: flex;
    gap: 20px;
    flex-direction: column;
    align-items: center;
    
    label {
      display: flex;
      align-items: center;
      gap: 5px;
      cursor: pointer;
    }
    
    @media screen and (${device(DeviceSize.tablet)}){
      flex-direction: row;
    }
  }
  .right {
    display: flex;
    gap: 10px;
    align-items: center;
    
    svg {
      color: var(--color-green);
    }
  }
  
  .right button{
    width: 100%;
    padding: 10px 15px;
    background-color: var(--color-primary-1);
    border: 1px solid var(--color-primary);
    color: var(--color-white);
    cursor: pointer;
    &:hover {
      background-color: var(--color-primary);
    }
    
  }
  
`