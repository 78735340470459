export enum DeviceSize {
	mobile,
	tablet,
	desktop,
	largeDesktop,
}

export const device = (deviceSize: DeviceSize) => {
	switch (deviceSize){
		case DeviceSize.mobile:
			return `max-width: 599px`
		case DeviceSize.tablet:
			return `min-width: 600px`
		case DeviceSize.desktop:
			return `min-width: 1200px`
		case DeviceSize.largeDesktop:
			return `min-width: 1800px`
		default:
			return `max-width: 599px`
	}
}
