import { Meal } from './Invitation.type'

export enum GuestStatus {
	ADDBYADMIN = 'ADDBYADMIN',
	ADDBYGUEST = 'ADDBYGUEST'
}

export enum GuestPresence {
	PRESENT = 'PRESENT',
	ABSENT = 'ABSENT',
	PENDING = 'PENDING'
}

export interface Guest {
	firstname: string
	lastname: string
	meal: Meal
	status: GuestStatus
	cocktailPresence: GuestPresence
  dinerPresence: GuestPresence
  isVegetarian: boolean
}
