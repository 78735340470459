
import styled from 'styled-components'
import {device, DeviceSize} from "../../global.style";

export const RsvpFormComponentStyle = styled.div`
	margin: 55px 10px;
	width: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;
`

export const SearchBar = styled.div`
	background: var(--color-white);
  width: 100%;
	height: 60px;
	display: flex;
	border: solid 1px var(--color-primary);
	align-items: center;
	gap:10px;
	
	@media screen and (${device(DeviceSize.tablet)}){
    width: 500px;
  }
	
	svg {
	  margin-left: 10px;
	  color: var(--color-primary);
	  font-size: 1.2rem;
	}
	
	input {
	  width: 100%;
	  height: 100%;
	  border: none;
	  outline: none;
	}
`

export const ListResult = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media screen and (${device(DeviceSize.tablet)}){
    width: 500px;
  }
`
