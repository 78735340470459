import { Guest, GuestPresence, GuestStatus } from './Guest'
import { FirestoreDataConverter, QueryDocumentSnapshot, DocumentData, SnapshotOptions, WithFieldValue } from 'firebase/firestore'
import firebase from 'firebase/compat'

export enum ConfirmationStatus {
	PENDING = 'PENDING',
	CONFIRMED = 'CONFIRMED',
	REFUSED = 'REFUSED'
}

export enum Meal {
	ADULT = 'ADULT',
	CHILD = 'CHILD'
}

export interface Invitation {
	id: string,
	invitationCocktail: boolean
	invitationDiner: boolean
	guests: Array<Guest>
	email: string
	confirmation: ConfirmationStatus
  names: Array<string>
}

export namespace Invitation {

	export const converter: FirestoreDataConverter<Invitation> = {
		toFirestore(modelObject: WithFieldValue<Invitation>): DocumentData {
			const document = {
				...modelObject
			}
			// @ts-ignore
			delete document.id
			return document
		},
		fromFirestore(snapshot: QueryDocumentSnapshot<DocumentData>, options?: SnapshotOptions): Invitation {
			const data = snapshot.data(options);
			return {
				id: snapshot.id,
				invitationDiner: data.invitationDiner,
				invitationCocktail: data.invitationCocktail,
				email: data.email,
				confirmation: data.confirmation as ConfirmationStatus,
				guests: data.guests.map((guest: any): Guest => {
					return {
						status: guest.status as GuestStatus,
						cocktailPresence: guest.cocktailPresence as GuestPresence,
						dinerPresence: guest.dinerPresence as GuestPresence,
						meal: guest.meal as Meal,
						firstname: guest.firstname,
						lastname: guest.lastname,
            isVegetarian: guest.isVegetarian
					}
				}),
        names: data.names
			}
		}
	}
}
