import React, { useContext, useEffect, useState } from 'react'
import { InvitationList, InvitationsComponentStyle } from './Invitations.component.style'
import { ServiceContext } from '../../context/Service.context'
import { Invitation } from '../../types/Invitation.type'
import { InvitationComponent } from './Invitation/Invitation.component'
import {GuestsStatistics} from "../../pages/Configuration/Configuration.page.style";
import {
    extractTotalAdult,
    extractTotalChild,
    extractTotalCocktail,
    extractTotalCocktailAdultConfirmed,
    extractTotalCocktailChildConfirmed,
    extractTotalDiner,
    extractTotalDinerAdultConfirmed,
    extractTotalDinerChildConfirmed,
    extractTotalGuest,
    extractTotalVegetarianComfirmed
} from '../../helper/Invitations/invitation.helper'

interface InvitationsComponentProps {}

export const InvitationsComponent: React.FC<InvitationsComponentProps> = (props: InvitationsComponentProps) => {
    const services = useContext(ServiceContext)
    const [ invitations, setInvitations ] = useState<Array<Invitation>>([])
    const [ totalGuest, setTotalGuest ] = useState(0)
    const [ totalAdult, setTotalAdult ] = useState(0)
    const [ totalChild, setTotalChild ] = useState(0)
    const [ totalCocktail, setTotalCocktail ] = useState(0)
    const [ totalDiner, setTotalDiner ] = useState(0)
    const [ totalDinerChildConfirmed, setTotalDinerChildConfirmed ] = useState(0)
    const [ totalDinerAdultConfirmed, setTotalDinerAdultConfirmed ] = useState(0)
    const [ totalCocktailChildConfirmed, setTotalCocktailChildConfirmed ] = useState(0)
    const [ totalCocktailAdultConfirmed, setTotalCocktailAdultConfirmed ] = useState(0)
    const [ totalVegetarianComfirmed, setTotalVegetarianComfirmed ] = useState(0)

    useEffect(() => {
        const unsubscribe = services!.invitationService.getInvitationObserver(initialInvitation)
        return () => {
            unsubscribe()
        }
    }, [])

    useEffect(() => {
        setTotalGuest(extractTotalGuest(invitations))
        setTotalAdult(extractTotalAdult(invitations))
        setTotalChild(extractTotalChild(invitations))
        setTotalCocktail(extractTotalCocktail(invitations))
        setTotalDiner(extractTotalDiner(invitations))
        setTotalCocktailAdultConfirmed(extractTotalCocktailAdultConfirmed(invitations))
        setTotalCocktailChildConfirmed(extractTotalCocktailChildConfirmed(invitations))
        setTotalDinerAdultConfirmed(extractTotalDinerAdultConfirmed(invitations))
        setTotalDinerChildConfirmed(extractTotalDinerChildConfirmed(invitations))
        setTotalVegetarianComfirmed(extractTotalVegetarianComfirmed(invitations))
    }, [invitations])

    const initialInvitation = (invitationsFromDatabase: Array<Invitation>) => {
        setInvitations(invitationsFromDatabase)
    }

    return (
        <InvitationsComponentStyle>
            <h2>Listes des invitations</h2>
            <GuestsStatistics>
                <ul>
                    <li>
                        <div>Nombre total d'invités:</div>
                        <div>{totalGuest}</div>
                    </li>
                    <li>
                        <div>Nombre adultes:</div>
                        <div>{totalAdult}</div>
                    </li>
                    <li>
                        <div>Nombre enfants:</div>
                        <div>{totalChild}</div>
                    </li>
                    <li>
                        <div>Nombre cocktail:</div>
                        <div>{totalCocktail}</div>
                    </li>
                    <li>
                        <div>Nombre adultes cocktail confirmé:</div>
                        <div>{totalCocktailAdultConfirmed}</div>
                    </li>
                    <li>
                        <div>Nombre enfants cocktail confirmé:</div>
                        <div>{totalCocktailChildConfirmed}</div>
                    </li>
                    <li>
                        <div>Nombre dîner + soirée:</div>
                        <div>{totalDiner}</div>
                    </li>
                    <li>
                        <div>Nombre adultes dîner + soirée confirmé:</div>
                        <div>{totalDinerAdultConfirmed}</div>
                    </li>
                    <li>
                        <div>Nombre enfants dîner + soirée confirmé:</div>
                        <div>{totalDinerChildConfirmed}</div>
                    </li>
                    <li>
                        <div>Nombre Végétarien</div>
                        <div>{totalVegetarianComfirmed}</div>
                    </li>
                </ul>
            </GuestsStatistics>
            <InvitationList>
                {invitations.map((invitation) => (
                    <InvitationComponent invitation={invitation}/>
                ))}
            </InvitationList>
        </InvitationsComponentStyle>
    )
}
