import styled from 'styled-components'
import { ContentLayoutStyle } from '../Layout/Content.layout.style'
import { device, DeviceSize } from '../../global.style'

export const DayCounterComponentStyle = styled(ContentLayoutStyle)`
	margin-top: 100px;
	margin-bottom: 55px;
	display: flex;
	flex-direction: column;
	justify-content: center;
  @media screen and (${device(DeviceSize.desktop)}){
   flex-direction: row;
  }
`

export const DayCounterTitle = styled.div`
	background-color: var(--color-primary-1)
`

export const DayCounterTitleWithBorder = styled.div`
  margin: 15px;
	padding: 15px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: var(--color-white);
	border: 1px solid var(--color-grey-1);
	
  @media screen and (${device(DeviceSize.desktop)}){
    margin: 35px;
    padding: 35px;
  }
	
	& > div:first-child {
		text-transform: capitalize;
		font-size: 1.3rem;
		margin-bottom: 15px;
	}
	
	& > div:last-child {
		font-family: Great Vibes, Roboto, serif;
		font-size: 3rem;
	}
	
`

export const CountersStyle = styled.div`
	background-color: var(--color-primary);
	color: var(--color-white);
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	align-items: stretch;
`

export const CounterStyleWithBorder = styled.div`
  margin: 15px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--color-white);
  border: 1px solid var(--color-grey-1);
	min-width: 160px;

  @media screen and (${device(DeviceSize.desktop)}){
    margin: 35px 12.5px 35px 12.5px;
	  &:last-child {
    	margin: 35px 35px 35px 12.5px;
	  }
	  &:first-child {
    	margin: 35px 12.5px 35px 35px;
	  }
  }
	
	& > div:first-child {
    font-family: Great Vibes, Roboto, serif;
    font-size: 2rem;
	}
	
	& > div:last-child {
		text-transform: uppercase;
	}
`
