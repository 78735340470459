import React from 'react'
import { CenterTopHeartComponentStyle, Heart, TextDate, TextNames } from './CenterTopHeart.component.style'
import { useTranslation } from 'react-i18next'

interface CenterTopHeartComponentProps {}

export const CenterTopHeartComponent: React.FC<CenterTopHeartComponentProps> = (props: CenterTopHeartComponentProps) => {
	const { t } = useTranslation()
  return (
  	<CenterTopHeartComponentStyle>
		  <Heart>
			  <TextNames>
			    {t('saveTheDate')}
			  </TextNames>
			  <TextDate>
				  17/09/2022
			  </TextDate>
		  </Heart>
	  </CenterTopHeartComponentStyle>
  )
}
