import styled from 'styled-components'

export const ConfigurationPageStyle = styled.div`
	margin: 25px 10px;
`

export const ConnexionButton = styled.button`
	display: flex;
	align-items: center;
	gap: 15px;
	padding: 15px 25px;
	background-color: var(--color-primary);
	border-radius: 25px;
	border: 2px solid var(--color-grey-1);
	color: var(--color-white);
  cursor: pointer;
	
	&:hover{
		background-color: var(--color-primary-1);
	}
`

export const AddInvite = styled.button`
  display: flex;
  align-items: center;
  background-color: var(--color-primary);
  border: 1px solid var(--color-grey-1);
  color: var(--color-white);
  cursor: pointer;
	padding: 10px 15px;

  &:hover{
    background-color: var(--color-primary-1);
  }
`

export const GuestsStatistics = styled.div`
    ul li {
      display: flex;
      gap: 15px;
    }
`