import React from 'react'
import { HiddenChangeHeaderComponentStyle } from './HiddenChangeHeader.component.style'

interface HiddeChangeHeaderComponentProps {
  refScroll: any
}

export const HiddenChangeHeaderComponent: React.FC<HiddeChangeHeaderComponentProps> = ({ refScroll }: HiddeChangeHeaderComponentProps) => {

  return (
    <HiddenChangeHeaderComponentStyle ref={refScroll} />
  )
}
