import styled from 'styled-components'
import { ContentLayoutStyle } from '../Layout/Content.layout.style'

export const LocalisationComponentStyle = styled(ContentLayoutStyle)`
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
`

export const LocalisationTitle = styled.h1`
	color: var(--color-primary);
	font-family: Great Vibes, Roboto, serif;
	font-size: 3rem;
`
