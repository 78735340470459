import React from 'react'
import { RsvpComponentStyle, RsvpRedirectButton, RsvpTitle } from './Rsvp.component.style'
import { useTranslation } from 'react-i18next'
import { RsvpFormComponent } from '../RsvpForm/RsvpForm.component'

interface RsvpComponentProps {}

export const RsvpComponent: React.FC<RsvpComponentProps> = (props: RsvpComponentProps) => {
	const { t } = useTranslation()

  return (
  	<RsvpComponentStyle id="rsvp">
		  <RsvpTitle>
			  { t('rsvp.title-long') }
		  </RsvpTitle>
		  <RsvpFormComponent />
	  </RsvpComponentStyle>
  )
}
