import React, {useState} from 'react'
import {ConfirmGuestComponentStyle} from "./ConfirmGuest.component.style";
import {Guest, GuestPresence} from "../../../types/Guest";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface ConfirmGuestComponentProps {
  guest: Guest
  isInviteToDiner: boolean
  confirmGuestInvitation: (guest: Guest, isPresentCocktail: boolean, isPresentDiner: boolean, isVegetarian: boolean) => void
}

export const ConfirmGuestComponent: React.FC<ConfirmGuestComponentProps> = ({
                                                                              guest,
                                                                              confirmGuestInvitation,
                                                                              isInviteToDiner
                                                                            }: ConfirmGuestComponentProps) => {

  const {t} = useTranslation()
  const buttonConfirmText = () => {
    return isInviteToDiner ? t('rsvp.button-confirm-cocktail-diner') : t('rsvp.button-confirm-cocktail')
  }

  const [isPresentDiner, setIsPresentDiner] = useState(false)
  const [isPresentCocktail, setIsPresentCocktail] = useState(false)
  const [isVegetarian, setIsVegetarian] = useState(false)

  const isConfirmed = () => {
    return guest.dinerPresence !== GuestPresence.PENDING && guest.cocktailPresence !== GuestPresence.PENDING
  }

  return (
    <ConfirmGuestComponentStyle>
      {
        (!isConfirmed()) && (
          <>
            <div className="left">
              <div>{guest.lastname} {guest.firstname}</div>
              <div>
                <label><input type="checkbox" onChange={(e) => {
                  setIsPresentCocktail(e.target.checked)
                }}/>{t('rsvp.cocktail-presence')}</label>
                {isInviteToDiner && (
                  <>
                    <label><input type="checkbox" onChange={(e) => {
                      setIsPresentDiner(e.target.checked)
                    }}/>{t('rsvp.diner-presence')}</label>
                    <label><input type="checkbox" onChange={(e) => {
                      setIsVegetarian(e.target.checked)
                    }}/>{t('rsvp.meal-vegetarian')}</label>
                  </>
                )
                }
              </div>
            </div>
            <div className="right">
              <div>
                <button
                  onClick={() => confirmGuestInvitation(guest, isPresentCocktail, isPresentDiner, isVegetarian)}>{t('rsvp.confirmation-button')}</button>
              </div>
            </div>
          </>
        )
      }
      {
        isConfirmed() && (
          <>
            <div className="left">
              <div>{guest.lastname} {guest.firstname}</div>
            </div>
            <div  className="right">
              <span>{ t('rsvp.thanks-confirmation') }</span>
              <FontAwesomeIcon icon={'check-circle'} size={'2x'}/>
            </div>
          </>
        )
      }
    </ConfirmGuestComponentStyle>
  )
}