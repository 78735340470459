import React, {ChangeEvent, ChangeEventHandler, useContext, useState} from 'react'
import { RsvpFormComponentStyle, SearchBar, ListResult } from './RsvpForm.component.style'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTranslation} from "react-i18next";
import {ServiceContext} from "../../context/Service.context";
import {Invitation} from "../../types/Invitation.type";
import {ResultInvitationComponent} from "./ResultInvitation/ResultInvitation.component";
import {ConfirmInvitationComponent} from "./ConfirmInvitation/ConfirmInvitation.component";

interface RsvpFormComponentProps {}

export const RsvpFormComponent: React.FC<RsvpFormComponentProps> = (props: RsvpFormComponentProps) => {

  const [invitationSelected, setInvitationSelected]  = useState<Invitation | undefined>(undefined)
  const [invitationResult, setInvitationResult] = useState<Array<Invitation>>([])
  const [search, setSearch] = useState("")
  const {t} = useTranslation()
  const services = useContext(ServiceContext)
  const handleChangeSearch = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length > 2) {
      setInvitationResult(await services!.invitationService.searchInvitationByName(event.target.value.toUpperCase()))
      setSearch(event.target.value.toUpperCase())
    }
  }

  const onInvitationSelectedHandler = (invitation: Invitation) => {
    services?.invitationService.getInvitationByIdObserver(invitation.id, setInvitationSelected)
  }

  return (
  	<RsvpFormComponentStyle>
      { invitationSelected == null && (
        <>
          <SearchBar>
            <FontAwesomeIcon icon="magnifying-glass" />
            <input type="text" onChange={handleChangeSearch} placeholder={t('search.placeholder')}/>
          </SearchBar>
          <ListResult>
            {invitationResult.map((invitation) => (
              <ResultInvitationComponent key={invitation.id} invitation={invitation} search={search} onInvitationSelected={onInvitationSelectedHandler}/>
            ))}
          </ListResult>
        </>
      )}

      {
        invitationSelected && (
          <ConfirmInvitationComponent invitation={invitationSelected} />
        )
      }
	  </RsvpFormComponentStyle>
  )
}
