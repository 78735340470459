import styled from 'styled-components'

interface LanguageComponentStyleProps {
	header?: boolean
}

export const LanguageComponentStyle = styled.div<LanguageComponentStyleProps>`
	width: 100%;
	color: inherit;
	font-size: inherit;
	cursor: pointer;	
	display: flex;
	align-items: center;
	gap: 5px;
`

export const FlagStyle = styled.div`
	width: 25px;
	height: 17px;
`
