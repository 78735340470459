import styled from 'styled-components'
import { device, DeviceSize } from '../../../global.style'

interface LocalisationCartComponentStyle {
	inverse: boolean
}

export const LocalisationCartComponentStyle = styled.div<LocalisationCartComponentStyle>`
	width: 100%;
	background: var(--color-primary-2);
	display: flex;
	flex-direction: column;
	margin: 25px 0;

  @media screen and (${device(DeviceSize.tablet)}){
    flex-direction: ${props => props.inverse ? 'row': 'row-reverse' };
    justify-content: ${props => props.inverse ? '': 'space-between' };
  }
`

export const LocalisationImage = styled.img`
	width: 100%;
	
  @media screen and (${device(DeviceSize.tablet)}){
	  max-height: 300px;
	  width: auto;
  }
`

export const LocalisationInformationStyle = styled.div`
	h3 {
		margin: 15px 10px;
		font-size: 1.5rem;
		color: var(--color-primary);
	}
  @media screen and (${device(DeviceSize.tablet)}){
	  padding: 25px 35px	;
  }
`

export const InformationsDetailStyle = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
	margin: 20px 15px;
	
	svg {
		color: var(--color-primary);
	}
	
	div {
		display: flex;
		align-items: center;
		gap: 10px;
		font-size: 1.2rem;
	}
`
