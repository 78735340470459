import React from 'react'
import { HomePageStyle } from './Home.page.style'
import { HeaderComponent } from '../../components/Header/Header.component'
import { CenterTopHeartComponent } from '../../components/CenterTopHeart/CenterTopHeart.component'
import { DayCounterComponent } from '../../components/DayCounter/DayCounter.component'
import { LocalisationComponent } from '../../components/Localisation/Localisation.component'
import { RsvpComponent } from '../../components/Rsvp/Rsvp.component'
import { GiftListComponent } from '../../features/giftList/GiftList.component'

interface HomePageProps {}

export const HomePage: React.FC<HomePageProps> = (props: HomePageProps) => {

  return (
  	<HomePageStyle>
		  <HeaderComponent />
			<CenterTopHeartComponent />
		  <DayCounterComponent />
		  <LocalisationComponent />
		  <RsvpComponent />
		  <GiftListComponent />
	  </HomePageStyle>
  )
}
