import styled from 'styled-components'
import { ContentLayoutStyle } from '../Layout/Content.layout.style'

export const RsvpComponentStyle = styled(ContentLayoutStyle)`
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
`

export const RsvpTitle = styled.h1`
	color: var(--color-primary);
	font-family: Great Vibes, Roboto, serif;
	font-size: 3rem;
`

export const RsvpRedirectButton = styled.a`
	background-color: var(--color-primary);
	padding: 15px 35px;
	font-weight: bold;
	color: var(--color-white);
	cursor: pointer;
	text-decoration: none;
	
	&:hover {
		background-color: var(--color-primary-1);
	}
`
