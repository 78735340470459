import {Invitation, Meal} from "../../types/Invitation.type";
import {GuestPresence} from "../../types/Guest";

export const extractTotalGuest = (invitations: Array<Invitation>): number => {
    return invitations.reduce((acc, value) => {
        return acc += value.guests.length
    }, 0)
}

export const extractTotalAdult = (invitation: Array<Invitation>): number => {
    return invitation.reduce((acc, invitation) => {
        return acc += invitation.guests.filter((guest) => guest.meal === Meal.ADULT).length
    }, 0)
}

export const extractTotalChild = (invitation: Array<Invitation>): number => {
    return invitation.reduce((acc, invitation) => {
        return acc += invitation.guests.filter((guest) => guest.meal === Meal.CHILD).length
    }, 0)
}

export const extractTotalCocktail = (invitation: Array<Invitation>): number => {
    return invitation.filter((invitation) => invitation.invitationCocktail).reduce((acc, invitation) => {
        return acc += invitation.guests.length
    }, 0)
}

export const extractTotalCocktailAdultConfirmed = (invitation: Array<Invitation>): number => {
    return invitation.filter((invitation) => invitation.invitationCocktail).reduce((acc, invitation) => {
        return acc += invitation.guests.filter((guest) => guest.meal === Meal.ADULT && guest.cocktailPresence === GuestPresence.PRESENT).length
    }, 0)
}

export const extractTotalCocktailChildConfirmed = (invitation: Array<Invitation>): number => {
    return invitation.filter((invitation) => invitation.invitationCocktail).reduce((acc, invitation) => {
        return acc += invitation.guests.filter((guest) => guest.meal === Meal.CHILD && guest.cocktailPresence === GuestPresence.PRESENT).length
    }, 0)
}

export const extractTotalDiner = (invitation: Array<Invitation>): number => {
    return invitation.filter((invitation) => invitation.invitationDiner).reduce((acc, invitation) => {
        return acc += invitation.guests.length
    }, 0)
}

export const extractTotalDinerAdultConfirmed = (invitation: Array<Invitation>): number => {
    return invitation.filter((invitation) => invitation.invitationDiner).reduce((acc, invitation) => {
        return acc += invitation.guests.filter((guest) => guest.meal === Meal.ADULT && guest.dinerPresence === GuestPresence.PRESENT).length
    }, 0)
}
export const extractTotalDinerChildConfirmed = (invitation: Array<Invitation>): number => {
    return invitation.filter((invitation) => invitation.invitationDiner).reduce((acc, invitation) => {
        return acc += invitation.guests.filter((guest) => guest.meal === Meal.CHILD && guest.dinerPresence === GuestPresence.PRESENT).length
    }, 0)
}
export const extractTotalVegetarianComfirmed = (invitation: Array<Invitation>): number => {
    return invitation.filter((invitation) => invitation.invitationDiner).reduce((acc, invitation) => {
        return acc += invitation.guests.filter((guest) => guest.isVegetarian && guest.dinerPresence === GuestPresence.PRESENT).length
    }, 0)
}
