import React from 'react'
import { GuestComponentStyle } from './Guest.component.style'
import { Guest, GuestPresence } from '../../types/Guest'
import { TagColor, TagInline } from '../Common/Tag.component.style'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ConfirmationStatus } from '../../types/Invitation.type'

interface GuestComponentProps {
	guest: Guest
  isInviteToDiner: boolean
	confirmationStatus: ConfirmationStatus
}

export const GuestComponent: React.FC<GuestComponentProps> = ({ guest, isInviteToDiner, confirmationStatus }: GuestComponentProps) => {

  const presenceCocktail = () => {
    if (guest.cocktailPresence === GuestPresence.PRESENT){
      return (<FontAwesomeIcon className="present" icon="martini-glass-citrus" size="1x"/>)
    }

    if (guest.cocktailPresence === GuestPresence.PENDING){
      return (<FontAwesomeIcon className="pending" icon="martini-glass-citrus" size="1x"/>)
    }

    if (guest.cocktailPresence === GuestPresence.ABSENT){
      return (<FontAwesomeIcon className="absent" icon="martini-glass-citrus" size="1x"/>)
    }
  }

  const isVegetarian = () => {
  	if (confirmationStatus === ConfirmationStatus.PENDING){
		  return (<FontAwesomeIcon className="pending" icon="seedling" size="1x"/>)
	  }

	  if (guest.isVegetarian){
		  return (<FontAwesomeIcon className="present" icon="seedling" size="1x"/>)
	  }

	  if (!guest.isVegetarian){
		  return (<FontAwesomeIcon className="absent" icon="seedling" size="1x"/>)
	  }
  }

  const presenceDiner = () => {
    if (!isInviteToDiner) {
      return null
    }

    if (guest.dinerPresence === GuestPresence.PRESENT){
      return (<FontAwesomeIcon className="present" icon="utensils" size="1x"/>)
    }

    if (guest.dinerPresence === GuestPresence.PENDING){
      return (<FontAwesomeIcon className="pending" icon="utensils" size="1x"/>)
    }

    if (guest.dinerPresence === GuestPresence.ABSENT){
      return (<FontAwesomeIcon className="absent" icon="utensils" size="1x"/>)
    }
  }

	const prenceIcon = () => {
		if (guest.cocktailPresence || guest.dinerPresence === GuestPresence.PRESENT){
			return (<FontAwesomeIcon className="present" icon="circle-check" size="1x"/>)
		}
		if (guest.cocktailPresence || guest.dinerPresence === GuestPresence.PENDING){
			return (<FontAwesomeIcon className="pending" icon="circle-question" size="1x"/>)
		}
		if (guest.cocktailPresence || guest.dinerPresence === GuestPresence.ABSENT){
			return (<FontAwesomeIcon className="absent" icon="circle-xmark" size="1x"/>)
		}
	}

  return (
	  <GuestComponentStyle>
		  <div>{ guest.firstname } { guest.lastname }</div>
		  <TagInline tagColor={TagColor.primary}>{guest.meal.toLowerCase()}</TagInline>
		  {
        presenceCocktail()
		  }
      {
        presenceDiner()
      }
		  {
		  	isVegetarian()
		  }

	  </GuestComponentStyle>
  )
}
