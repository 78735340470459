import React from 'react'
import { EngagementRingIconStyle } from './EngagementRing.icon.style'

interface EngagementRingIconProps {
  color?: string
}

export const EngagementRingIcon: React.FC<EngagementRingIconProps> = ({ color }: EngagementRingIconProps) => {

  return (
    <EngagementRingIconStyle color={color}>
      <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.88 121.58">
        <path fillRule="evenodd"
          d="M57.46,58.59c-.32.5-.64,1-.94,1.52A35.13,35.13,0,0,0,65.09,103l-.83.92a27.15,27.15,0,1,1-6.8-45.32ZM15.51,36.89a2.47,2.47,0,0,1,.36-.11l31.08-9-8.11-7.17-20,5.77-3.3,10.47ZM36,11.38l-.14,0A4.48,4.48,0,0,0,33.64,8.7a4.42,4.42,0,0,0-3.48-.43l-.05-.15A4.4,4.4,0,0,0,32.83,5.9a4.44,4.44,0,0,0,.44-3.49l.14,0a4.38,4.38,0,0,0,2.23,2.72,4.42,4.42,0,0,0,3.48.44l0,.14a4.43,4.43,0,0,0-2.72,2.22A4.44,4.44,0,0,0,36,11.38Zm-19.8,5.49-.27.08a8.31,8.31,0,0,0-4.16-5.1A8.29,8.29,0,0,0,5.25,11l-.08-.27A8.25,8.25,0,0,0,10.26,6.6,8.31,8.31,0,0,0,11.08.08L11.35,0a8.26,8.26,0,0,0,4.16,5.09A8.2,8.2,0,0,0,22,5.9l.08.28A8.27,8.27,0,0,0,17,10.34a8.28,8.28,0,0,0-.81,6.53ZM5.9,29.92l-.15,0a4.43,4.43,0,0,0-2.22-2.72A4.44,4.44,0,0,0,0,26.81l0-.15a4.4,4.4,0,0,0,2.72-2.22A4.42,4.42,0,0,0,3.15,21l.15-.05a4.4,4.4,0,0,0,2.22,2.72A4.44,4.44,0,0,0,9,24.07l0,.14a4.41,4.41,0,0,0-2.72,2.23,4.41,4.41,0,0,0-.43,3.48ZM27.06,50.53,12.3,39.31a1.78,1.78,0,0,1-.62-1.91l4.07-12.91a1.72,1.72,0,0,1,1.31-1.19L38.8,17a1.75,1.75,0,0,1,1.64.37l10,8.85a1.75,1.75,0,0,1,.47,1.95L44.79,45.26a38.07,38.07,0,0,1,19.75,4.53,35.12,35.12,0,1,1,10.29,59c-.44.53-.89,1.06-1.36,1.58A38.18,38.18,0,1,1,27.06,50.53Zm46,5.48A38,38,0,0,1,83.15,72.83a36.36,36.36,0,0,1-2.55,27.25,25,25,0,1,0,.23-47.92A22.16,22.16,0,0,0,73.06,56ZM71.17,94.65l-.07.12A25,25,0,0,1,65,65.87l.91-1.41a27.13,27.13,0,0,1,5.26,30.19Z"/>
      </svg>
    </EngagementRingIconStyle>
  )
}
