import React from 'react'
import { FlagStyle, LanguageComponentStyle } from './Language.component.style'
import { BR, FR} from 'country-flag-icons/react/3x2'
import { useTranslation } from 'react-i18next'

interface LanguageComponentProps {
  header?: boolean
}

export const LanguageComponent: React.FC<LanguageComponentProps> = ({ header }: LanguageComponentProps) => {
  const { i18n } = useTranslation()

  const currentLanguage = i18n.language !== 'fr-FR' ? 'Français' : 'Português'
  const CurrentFlag = i18n.language !== 'fr-FR' ? FR: BR

  const changeLanguageHandler = () => {
    if (i18n.language === 'fr-FR') {
      i18n.changeLanguage('pt-BR')
    }else {
      i18n.changeLanguage('fr-FR')
    }
  }

  return (
    <LanguageComponentStyle onClick={changeLanguageHandler} header={header}>
      <FlagStyle><CurrentFlag /> </FlagStyle> <div>{ currentLanguage }</div>
    </LanguageComponentStyle>
  )
}
