import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { BurgerMenuButton, MenuBackDrop, MenuItem, MenuLink, MenuMobile, MenuTitle } from './MobileMenu.component.style'
import { useTranslation } from 'react-i18next'
import { LanguageComponent } from '../Language/Language.component'

export const MobileMenuComponent: React.FC = (p) => {
	const [ active, setActive ] = useState<boolean>(false)
	const { t } = useTranslation()
	return (
		<>
			<BurgerMenuButton onClick={ () => setActive(true) }>
					<FontAwesomeIcon icon="bars" size="2x"/>
			</BurgerMenuButton>
			{
				<>
            <MenuBackDrop className={active?'open':''} onClick={ () => setActive(false) }/>
            <MenuMobile className={active?'open':''}>
	            <MenuLink href="#localisation">
		            {t('localisation.title')}
	            </MenuLink>
	            <MenuLink href="#rsvp">
		            {t('rsvp.title')}
	            </MenuLink>
	            <MenuLink href="#gift">
		            {t('gift.title-menu')}
	            </MenuLink>
							<MenuTitle>{t('language')}</MenuTitle>
							<MenuItem>
								<LanguageComponent />
							</MenuItem>
            </MenuMobile>
        </>
			}
		</>
	)
}
