import React, {useContext} from 'react'
import { Invitation } from '../../../types/Invitation.type'
import {
  DeleteInvitation,
  GuestList,
  InvitationComponentStyle,
  InvitationsPeriodIcon
} from './Invitation.component.style'
import { GuestComponent } from '../../Guest/Guest.component'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ServiceContext} from "../../../context/Service.context";

interface InvitationComponentProps {
	invitation: Invitation
}

export const InvitationComponent: React.FC<InvitationComponentProps> = ({ invitation }: InvitationComponentProps) => {
  const services = useContext(ServiceContext)

  const deleteInvitation = () => {
    services?.invitationService.deleteInvitationById(invitation.id)
  }
  const cocktailIcon = () => {
		if (invitation.invitationCocktail) {
			return (<FontAwesomeIcon className="present" icon="martini-glass-citrus" size="1x"/>)
		}else {
			return (<FontAwesomeIcon className="absent" icon="martini-glass-citrus" size="1x"/>)
		}
	}

	const dinerIcon = () => {
		if (invitation.invitationDiner) {
			return (<FontAwesomeIcon className="present" icon="utensils" size="1x"/>)
		}else {
			return (<FontAwesomeIcon className="absent" icon="utensils" size="1x"/>)
		}
	}

	return (
		<InvitationComponentStyle>
      <DeleteInvitation onClick={deleteInvitation}>
        <FontAwesomeIcon icon="trash" size="1x"/>
      </DeleteInvitation>
			<InvitationsPeriodIcon>
				{ cocktailIcon() }
				{ dinerIcon() }
			</InvitationsPeriodIcon>
			<div>

				<h4>Invité(e)s :</h4>
				<GuestList>
					{ invitation.guests.map((guest) => (
						<GuestComponent guest={ guest } isInviteToDiner={invitation.invitationDiner} confirmationStatus={invitation.confirmation}/>
					)) }
				</GuestList>
			</div>

		</InvitationComponentStyle>
	)
}
