import styled from 'styled-components'

export const ConfirmInvitationStyle = styled.div`
  width: 100%;
`

export const GuestConfirmationList = styled.div`
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`