import styled from 'styled-components'
import { device, DeviceSize } from '../../global.style'
import { EngagementRingIconStyle } from '../icons/EngagementRing/EngagementRing.icon.style'

export interface HeaderComponentStyleProps {
	transparent: boolean
}

export const HeaderComponentStyle = styled.header<HeaderComponentStyleProps>`
	display: flex;
	justify-content: center;
	width: 100%;
	background: ${props => props.transparent ? 'rgba(255, 255, 255,.1)' : 'var(--color-primary)' };
	height: 80px;
	position: fixed;
	top: 0;
	left: 0;
	color: ${props => props.transparent ? 'var(--color-primary)': 'var(--color-white)' };

  ${EngagementRingIconStyle}{
    fill: ${props => props.transparent ? 'var(--color-primary)': 'var(--color-white)' };
  }
`

export const HeaderContent = styled.div`
  display: flex;
	width: 100%;
	align-items: center;
	max-width: 1280px;
	padding: 0 5px;
	justify-content: space-between;
`

export const Logo = styled.div`
  font-family: Great Vibes, serif;
  font-size: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`

export const HeaderLinkItems = styled.div`
	display: none;
	font-size: 1.1rem;
	font-weight: 500;
	height: 100%;
  @media screen and (${device(DeviceSize.desktop)}){
    display: flex;
	  justify-content: center;
	  align-items: center;
	  gap: 30px;
  }
`

export const HeaderLinkItem = styled.a`
	color: inherit;
	text-decoration: none;
	white-space: nowrap;
`
