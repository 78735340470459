import styled from 'styled-components'

export const InvitationComponentStyle = styled.div`
	border: 2px solid var(--color-primary);
	padding: 5px 15px;
	border-radius: 5px;
	background-color: var(--color-white);
	min-width: 320px;
  position: relative;
`

export const GuestList = styled.div`
	margin-top: 5px;
	display: flex;
	flex-direction: column;
	gap: 5px;
`

export const InvitationsPeriodIcon = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
    .present {
      color: var(--color-green);
    }
    
    .absent {
      color: var(--color-red);
    }
`

export const DeleteInvitation = styled.div`
  position: absolute;
  top:0;
  right: 5px;
  cursor: pointer;
`