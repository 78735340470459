import React, {useContext} from 'react'
import {ConfirmInvitationStyle, GuestConfirmationList} from "./ConfirmInvitation.component.style";
import {useTranslation} from "react-i18next";
import {Invitation} from "../../../types/Invitation.type";
import {ConfirmGuestComponent} from "../ConfirmGuest/ConfirmGuest.component";
import {Guest} from "../../../types/Guest";
import {ServiceContext} from "../../../context/Service.context";

interface ConfirmInvitationComponentProps {
  invitation: Invitation
}

export const ConfirmInvitationComponent: React.FC<ConfirmInvitationComponentProps> = ({invitation}: ConfirmInvitationComponentProps) => {
  const { t } = useTranslation()
  const services = useContext(ServiceContext)

  const confirmGuestInvitation = (guest: Guest, isPresentCocktail: boolean, isPresentDiner: boolean, isVegetarian: boolean) => {
    services?.invitationService.confirmGuestInvitation(guest, invitation, isPresentCocktail, isPresentDiner, isVegetarian)
  }

  return (
  <ConfirmInvitationStyle>
    <h3>{t('rsvp.your-invitation')}</h3>
    <div>{t('rsvp.confirm.description')}</div>
    <GuestConfirmationList>
      {invitation.guests.map(guest => (
        <ConfirmGuestComponent key={guest.lastname+guest.firstname} guest={guest} isInviteToDiner={invitation.invitationDiner} confirmGuestInvitation={confirmGuestInvitation}/>
      ))}
    </GuestConfirmationList>

  </ConfirmInvitationStyle>
 )
}