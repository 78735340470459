import React, { useContext, useEffect, useState } from 'react'
import { AddInvite, ConfigurationPageStyle, ConnexionButton } from './Configuration.page.style'
import { getAuth, User, signInWithPopup, GoogleAuthProvider, browserLocalPersistence, setPersistence} from 'firebase/auth'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Cookie from 'js-cookie'
import { AddInvitationComponent } from '../../components/AddInvitation/AddInvitation.component'
import { ServiceContext } from '../../context/Service.context'
import { InvitationsComponent } from '../../components/Invitations/Invitations.component'



interface ConfigurationPageProps {}

export const ConfigurationPage: React.FC<ConfigurationPageProps> = (props: ConfigurationPageProps) => {
	const auth = getAuth()
	const provider = new GoogleAuthProvider();
	const [ user, setUser ] = useState<User | null >(null)
	const [ token, setToken ] = useState<string | undefined>(undefined)
	const [addInvite, setAddInvite] = useState<boolean>(false)

	useEffect(()=> {
		auth.onAuthStateChanged((user) => {
			setUser(auth.currentUser)
		})
	}, [auth])

	const invitationCreated = () => {
		setAddInvite(false)
	}

	const handleConnexionRequest = async () => {
		const result = await setPersistence(auth, browserLocalPersistence).then(() => {
			return signInWithPopup(auth, provider)
		})
		const credential = GoogleAuthProvider.credentialFromResult(result)
		const token = credential?.accessToken
		Cookie.set('credential', JSON.stringify(credential))
		setToken(token)
		setUser(result.user)
	}

	const handleAddInvite = () => {
		setAddInvite(true)
	}

	if (user === null) {
		return (
			<ConfigurationPageStyle>
				<ConnexionButton onClick={handleConnexionRequest}>Se connecter avec <FontAwesomeIcon icon={['fab', 'google']} size="2x"/></ConnexionButton>
			</ConfigurationPageStyle>
		)
	}

	return (
		<ConfigurationPageStyle>
			<h1>Configuration page</h1>

			{addInvite && (
				<AddInvitationComponent invitationCreated={invitationCreated} />
			)}
			{!addInvite && (
				<AddInvite onClick={handleAddInvite}>
					Ajouter une invitation
				</AddInvite>
			)
			}
			<InvitationsComponent />

		</ConfigurationPageStyle>
	)
}
