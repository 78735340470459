import styled from 'styled-components'

export const AddInvitationComponentStyle = styled.div`
	background-color: var(--color-white);
	border: 2px solid var(--color-primary);
	border-radius: 5px;
	padding: 5px 15px;
	
	h3 {
		color: var(--color-primary);
	}
	.submit {
		background-color: var(--color-primary);
		border: 1px solid var(--color-primary);
		padding: 10px 15px;
		color: var(--color-white);
		margin-top: 10px;
		font-weight: bold;
		
	}
`

export const GuestList = styled.div`
  .add-guest {
    background-color: var(--color-primary);
    color: var(--color-white);
    padding: 5px 7px;
    border-radius: 25px;
    border: 1px solid var(--color-primary);
    cursor: pointer;

    &:hover {
      background-color: var(--color-primary-1);
    }
  }
`
export const GuestComponentStyle = styled.div`
	padding: 10px 5px;
	display: flex;
	align-items: center;
	gap: 5px;
	
	input, select {
		border: 2px solid var(--color-primary);
		padding: 5px 10px;
		border-radius: 5px;
	}
	
	button {
		background-color:darkred;
		color: var(--color-white);
		padding: 5px 7px;
		border-radius: 25px;
		border:darkred;
		cursor: pointer;
		
		&:hover {
			background-color: red;
		}
	}
`
export const InvitationDetails = styled.div`
	div {
		margin: 5px 15px;
		
		label {
			display: flex;
			align-items: center;
			gap: 10px;
		}
	}
`
