import React from 'react'
import { ButtonText, LeetchiButtonStyle } from './LeetchiButton.style'
import { LeetchiIcon } from '../icon/Leetchi.icon'

interface LeetchiButtonComponentProps {}

export const LeetchiButtonComponent: React.FC<LeetchiButtonComponentProps> = (props: LeetchiButtonComponentProps) => {
  return (
	  <LeetchiButtonStyle href="http://www.leetchi.com/c/ivy-et-florian" target="_blank">
		  <LeetchiIcon />
		  <ButtonText>Je participe !</ButtonText>
	  </LeetchiButtonStyle>
  )
}
