import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { counter } from '@fortawesome/fontawesome-svg-core'

export interface Counter {
	day: number
	hour: number
	minute: number
	second: number
}

const calcTimeToWedding = (): Counter => {
	const weddingDate = dayjs('2022-09-17T15:00:00+02:00')

	const counterInit: Counter = {
		day: 0,
		hour: 0,
		minute: 0,
		second: 0
	}
	let weddingDayMillisecond = weddingDate.diff(dayjs())

	counterInit.day = Math.floor(weddingDayMillisecond / (24*60*60*1000))
	weddingDayMillisecond = weddingDayMillisecond - (counterInit.day * (24*60*60*1000))
	counterInit.hour = Math.floor(weddingDayMillisecond / (60*60*1000))
	weddingDayMillisecond = weddingDayMillisecond - (counterInit.hour * (60*60*1000))
	counterInit.minute = Math.floor(weddingDayMillisecond / (60*1000))
	weddingDayMillisecond = weddingDayMillisecond - (counterInit.minute * (60*1000))
	counterInit.second = Math.floor(weddingDayMillisecond / 1000)
	return counterInit
}

export const useCounter = (): [Counter] => {
	const [counter, setCounter] = useState<Counter>(calcTimeToWedding)
	useEffect(() => {
		const timer = setTimeout(() => {
			setCounter(calcTimeToWedding);
		}, 1000);

		return () => clearTimeout(timer);
	});
	return [counter]
}
