import styled from 'styled-components'

export const ResultInvitationStyle = styled.div`
  border: 1px solid var(--color-primary);
  border-top: none;
  padding: 15px 25px;
  background: var(--color-primary-2);
  width: 100%;
  
  &:hover{
    background: var(--color-primary-1);
    color: var(--color-white);
    cursor: pointer;
  }
`