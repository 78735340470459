import styled from 'styled-components'

export const HiddenChangeHeaderComponentStyle = styled.div`
	background-color: transparent;
	width: 1px;
	height: 1px;
	position: absolute;
	top: 100px;
	left: 0;
`
