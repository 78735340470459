import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { initializeApp } from 'firebase/app'
import { ConfigurationPage } from './pages/Configuration/Configuration.page'
import { ServiceContext } from './context/Service.context';
import { ServiceFactory } from './services/ServiceFactory'
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyAOQ8sNRO4HYu0rDfWshAACXYhx_ruB94U",
  authDomain: "wedding-ivyflorian.firebaseapp.com",
  projectId: "wedding-ivyflorian",
  storageBucket: "wedding-ivyflorian.appspot.com",
  messagingSenderId: "13469191633",
  appId: "1:13469191633:web:20ff8b017c0b383912a4f0"
}

const app = initializeApp(firebaseConfig)
const db = getFirestore(app)
const servicesFactory = new ServiceFactory(db)
ReactDOM.render(
  <React.StrictMode>
    <ServiceContext.Provider value={servicesFactory}>
      <BrowserRouter>
       <App />
      </BrowserRouter>
    </ServiceContext.Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
