import styled from 'styled-components'

export enum TagColor {
	primary,
	red,
	orange,
	green
}

interface TagProps {
	tagColor: TagColor
}

export const TagInline = styled.span<TagProps>`
	display: inline-block;
	background-color: ${(props) => {
		switch (props.tagColor){
			case TagColor.primary:
				return 'var(--color-primary)'
			case TagColor.red:
				return 'var(--color-red)'
			case TagColor.orange:
				return 'var(--color-orange)'
			case TagColor.green:
				return 'var(--color-green)'
			default:
				return 'var(--color-primary)'
		}
	}};
	color: white;
	font-weight: bold;
	font-size: 0.75rem;
	padding: 3px 5px;
	border-radius: 15px;
`
