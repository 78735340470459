import styled from 'styled-components'

export const GuestComponentStyle = styled.div`
	display: flex;
	align-items: center;
	gap: 4px;
	
	.present {
		color: var(--color-green);
	}
	
	.pending {
		color: var(--color-orange);
	}
	
	.absent {
		color: var(--color-red);
	}
	
`
