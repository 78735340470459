import {ConfirmationStatus, Invitation} from '../../types/Invitation.type'
import {deleteDoc, collection, doc, Firestore, getDocs, onSnapshot, query, setDoc, Unsubscribe, where} from 'firebase/firestore'
import {v4 as uuidv4} from 'uuid'
import {Guest, GuestPresence} from "../../types/Guest";

export class InvitationService {
	db: Firestore
	constructor(db: Firestore) {
		this.db = db
	}

	async addInvitation(invitation: Invitation) {
		await setDoc(doc(this.db, 'invitations', uuidv4()).withConverter(Invitation.converter), invitation)
	}

	async getInvitations(): Promise<Array<Invitation>> {
		const invitations: Array<Invitation> = []


		const querySnapshot = await getDocs(collection(this.db, 'invitations').withConverter(Invitation.converter))
		if (querySnapshot.empty){
			return []
		}
		querySnapshot.forEach((invitation) => {
			invitations.push(invitation.data())
		})

		return invitations
	}

	getInvitationObserver(initialValues: (invitations: Array<Invitation>) => void): Unsubscribe {
		return onSnapshot(collection(this.db, 'invitations').withConverter(Invitation.converter), (snapshot) => {
			const invitations: Array<Invitation> = []
			snapshot.forEach((doc) => {
				invitations.push(doc.data())
			})
			initialValues(invitations)
		})
	}

  getInvitationByIdObserver(invitationId: string, invitationObserver: (invitation: Invitation | undefined) => void): Unsubscribe {
    return onSnapshot(doc(this.db, 'invitations', invitationId).withConverter(Invitation.converter), (documentSnapshot) => {
      invitationObserver(documentSnapshot.data())
    })
  }

	async searchInvitationByName(searchName: string): Promise<Array<Invitation>> {
	  const querySnapshot = query(collection(this.db, 'invitations').withConverter(Invitation.converter), where('names', 'array-contains', searchName ))
    const snapshot = await getDocs(querySnapshot)
    const invitations: Array<Invitation> = []

    snapshot.forEach((invitation) =>{
      invitations.push(invitation.data())
    })

    return invitations
  }

  async confirmGuestInvitation(guest: Guest, invitation: Invitation, isPresentCocktail: boolean, isPresentDiner: boolean, isVegetarian: boolean) {
    const updatedInvitation: Invitation = {
      ...invitation
    }

    updatedInvitation.guests = updatedInvitation.guests.map(guest => {
      return {
        ...guest
      }
    })

    updatedInvitation.guests.forEach((updateGuest: Guest, index: number) => {
      console.log("searching")
      if (updateGuest.lastname === guest.lastname && updateGuest.firstname === guest.firstname) {
        console.log("find ")
        updatedInvitation.guests[index].cocktailPresence = isPresentCocktail ? GuestPresence.PRESENT : GuestPresence.ABSENT
        updatedInvitation.guests[index].dinerPresence = isPresentDiner ? GuestPresence.PRESENT : GuestPresence.ABSENT
        updatedInvitation.guests[index].isVegetarian = isVegetarian
      }
    })

    if(updatedInvitation.guests.every(guest => guest.cocktailPresence)) {
      updatedInvitation.confirmation = ConfirmationStatus.CONFIRMED
    }

    await setDoc(doc(this.db, 'invitations', updatedInvitation.id).withConverter(Invitation.converter), updatedInvitation)
  }

  async deleteInvitationById(invitationId: string): Promise<void> {
    await deleteDoc(doc(this.db, 'invitations', invitationId))
  }
}
