import React, { ChangeEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { GiftDescription, GiftListComponentStyle, GiftTitle, PixMessage, PixValue } from './GiftListComponent.style'
import { LeetchiButtonComponent } from './component/LeetchiButton.component'
import { createStaticPix, parsePix, PixError, PixObject } from 'pix-utils'

interface GiftListComponentProps {
}

export const GiftListComponent: React.FC<GiftListComponentProps> = (props: GiftListComponentProps) => {
	const { t, i18n } = useTranslation()
	const [ value, setValue ] = useState<number>(1)
	const [ pixImage, setPixImage ] = useState<string>('')

	const handleChangeValue = async (event: ChangeEvent<HTMLInputElement>) => {
		await setValue(parseFloat(event.target.value))
	}

	const generatePix = async () => {
		const pix = createStaticPix({
			merchantName: 'Ivy PEREIRA DE OLIVEIRA',
			merchantCity: 'BELO HORIZONTE',
			pixKey: '09704603681',
			infoAdicional: '',
			transactionAmount: value,
			txid: '',
		})

		if ('toImage' in pix) {
			setPixImage(await pix.toImage())
		}
	}

	const pixImageEmpty = (): boolean => {
		return pixImage === ''
	}

	useEffect(() => {
		generatePix()
	}, [ value ])


	return (
		<GiftListComponentStyle id="gift">
			<GiftTitle>{ t('gift.title') }</GiftTitle>
			{ i18n.language === 'fr-FR' && (
				<GiftDescription>
					<div>Nous aimerions faire notre lune de miel dans un lieu paradisiaque, nous avons choisi de partir dans les
						Îles polynésiennes françaises, Tahiti !
						<br/> <br/>Vous pouvez si vous le souhaitez nous aider en contribuant à la cagnotte en ligne mise en place !
					</div>
					<LeetchiButtonComponent/>
				</GiftDescription>
			) }

			{ i18n.language === 'pt-BR' && (
				<GiftDescription>
					<div>Vaquinha dos Noivos: Depois de um casamento dos sonhos, uma lua de mel no paraíso! Obrigada por nos
						ajudar a partir ao Tahiti!
						<PixValue>
							<div> Escolha o valor do PIX:</div>
							<div>
								<input type="number" value={ value } onChange={ handleChangeValue }/> R$
							</div>
						</PixValue>
						{ !pixImageEmpty() &&
							<img src={pixImage} />
						}
					</div>
				</GiftDescription>
			) }
		</GiftListComponentStyle>
	)
}
