// @ts-nocheck
import React, { useContext } from 'react'
import { AddInvitationComponentStyle, GuestList, InvitationDetails } from './AddInvitation.component.style'
import { ConfirmationStatus, Invitation, Meal } from '../../types/Invitation.type'
import {Guest, GuestPresence, GuestStatus} from '../../types/Guest'
import { Field, FieldArray, Form, Formik } from 'formik'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GuestComponentStyle } from './AddInvitation.component.style'
import { ServiceContext } from '../../context/Service.context'

interface AddInviteComponentProps {
	invitationCreated: () => void
}

export const AddInvitationComponent: React.FC<AddInviteComponentProps> = ({ invitationCreated }: AddInviteComponentProps) => {

	const invitationService = useContext(ServiceContext)
	const initialInvitation: Invitation = {
		invitationCocktail: false,
		invitationDiner: false,
		confirmation: ConfirmationStatus.PENDING,
		email: '',
		guests: [
			{
				firstname: '',
				lastname: '',
				meal: Meal.ADULT,
				cocktailPresence: GuestPresence.PENDING,
				dinerPresence: GuestPresence.PENDING,
        isVegetarian: false,
				status: GuestStatus.ADDBYADMIN
			}
		],
    names: [],
		id: ''
	}

	const addInvitationtHandle = async (invitation: Invitation) => {
		let names = []

		invitation.guests.map((guest) => {
			names.push(guest.lastname)
			names = names.concat(guest.lastname.split(' '))
		})

	  invitation.names = names
		console.log(invitation)
		await invitationService?.invitationService.addInvitation(invitation)
	}

	return (
		<AddInvitationComponentStyle>
			<h3>Nouvelle invitation</h3>
			<Formik
				initialValues={ initialInvitation }
				onSubmit={ async (values, actions) => {
					await addInvitationtHandle(values)
					actions.resetForm()
					invitationCreated()
				} }
			>
				{ ({ values }) => (
					<Form>
						<InvitationDetails>
							<div>
								<label>
									<Field name="invitationCocktail" type="checkbox"/>
									Cérémonique laïque + cocktail
								</label>
							</div>
							<div>
								<label>
									<Field name="invitationDiner" type="checkbox"/>
									Dîner + Soirée
								</label>
							</div>
						</InvitationDetails>
						<FieldArray name="guests">
							{ ({ insert, remove, push }) => (
								<GuestList>
									{ values.guests.length > 0 &&
									values.guests.map((guest, index) => (

										<GuestComponentStyle key={ index }>
											<div>
												<Field
													name={ `guests.${ index }.lastname` }
													placeholder="Nom"
													type="text"
												/>
											</div>
											<div>
												<Field
													name={ `guests.${ index }.firstname` }
													placeholder="Prénom"
													type="text"
												/>
											</div>
											<div>
												<Field
													name={ `guests.${ index }.meal` }
													as="select"
												>
													<option value={ Meal.ADULT }>Adulte</option>
													<option value={ Meal.CHILD }>Enfant</option>
												</Field>
											</div>
											<div>
												<button
													type="button"
													className="secondary"
													onClick={ () => remove(index) }
												>
													<FontAwesomeIcon icon="trash" size="1x"/>
												</button>
											</div>
										</GuestComponentStyle>
									)) }
									<button
										type="button"
										className="add-guest"
										onClick={ () => push({
											lastname: '',
											firstname: '',
											meal: Meal.ADULT,
											cocktailPresence: GuestPresence.PENDING,
											dinerPresence: GuestPresence.PENDING,
                      isVegetarian: false,
											status: GuestStatus.ADDBYADMIN
										} as Guest) }
									>
										<FontAwesomeIcon icon={'user-plus'} />
									</button>
								</GuestList>
							) }
						</FieldArray>
						<button className="submit" type="submit">Créer l'invitation</button>
					</Form>
				) }
			</Formik>
		</AddInvitationComponentStyle>
	)
}
