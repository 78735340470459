import React, {useEffect, useState} from 'react'
import {ResultInvitationStyle} from "./ResultInvitation.component.style";
import {Invitation} from "../../../types/Invitation.type";
import {Guest} from "../../../types/Guest";

interface ResultInvitationComponentProps {
  invitation: Invitation
  search: string
  onInvitationSelected: (invitation: Invitation) => void
}

export const ResultInvitationComponent: React.FC<ResultInvitationComponentProps> = ({invitation, search, onInvitationSelected}: ResultInvitationComponentProps) => {

  const [ guestsCoresponding, setGuestsCoresponding] = useState<Guest[]>([])
  useEffect(() => {
    invitation.guests.forEach((guest: Guest) => {
      if (guest.lastname.toUpperCase().includes(search.toUpperCase())) {
        setGuestsCoresponding(oldArray => [...oldArray, guest])
      }
    })
    console.log(guestsCoresponding)
  }, [])

  if (guestsCoresponding === []) {
    return null
  }

  return (
    <>
      {guestsCoresponding.map((guest) =>
        <ResultInvitationStyle onClick={() => onInvitationSelected(invitation)}>
          { guest.lastname } {guest.firstname}
        </ResultInvitationStyle>
      )}
    </>
 )
}
