import React from 'react'

interface LeetchiIconProps {}

export const LeetchiIcon: React.FC<LeetchiIconProps> = (props: LeetchiIconProps) => {
  return (
	  <svg version="1.1" id="layer" x="0px" y="0px" viewBox="-132.938 201.641 159.924 156.691"
		   xmlns="http://www.w3.org/2000/svg">
		  <path fill="#FFFFFF"
			  d="M-53,206c-40.8,0-73.9,33.1-73.9,73.9c0,23.6,11.1,44.6,28.3,58.1c1.2-1.4,2.3-2.7,3.2-3.7l0,0l0.9-1 c0.5-0.5,9.9-11.1,20.2-15.5c1.4-6.7,0.1-12.9-0.6-15.7l-0.9-0.7l-2.5,2.9l-2-2.3l-2.9,2.5l-1.4-2.8l-2.6,3.1l-1.8-3.6l-3.2,2.4 l-0.4-3.7l-3.7,1.7l0.3-4.1l-3.8,1.4l0.7-4.6l-5.1-0.9l2.7-4.2l-4.8-1.4l3.4-3.9l-4.7-2.5l4-3.3l-4.4-3l3.9-2.5l-3.7-2.3l3.8-2.9 l-2.9-3.9l4.4-1.7l-3.2-4.5l4.8-1.2l-2.2-4.2l4.5-0.4l-1.2-4.8l4.6,0.4l-0.5-4.3l4.7,1l1.4-4.3l3.3,2.3l2.6-3.6l2.5,2.9l4.6-3 l0.7,3.7l5-1.2l-0.1,3.5l4.7-0.8l-1,3.5l4.8,0.1l-1.7,3.2l4.3,1.1l-1.2,3.2l3.8,1.7l-1.5,2.7l3.3,1.6l-1.5,2.8l3.1,1.4l-1.8,2.8 l2.9,2.4l-1.4,1.9l-0.3,0.5l2.4,2c-7,9.4-26,8.2-26,8.2c0.8,6.2,2.4,6.8,7.6,7.1c5.2,0.3,15.6-4.3,15.6-4.3l0.3,0.5l2.1,3l-3.6,0.9 l1.2,3.4l-3.6,0l-1.1,3.9l-3.1-0.7l-2,4l-2.6-1.3l-1.5,2.9l-1.4-0.8c0.8,2.9,2,8.7,1.1,15.3c8.8-2,19.5-6.5,25.5-10.2l0-1.3 l-3.9,0.8l1.2-3.6l-4.1,0l2-3.5l-4-0.3l2.6-3.9l-4.2-3l4.2-2.7l-3.8-3.3l4.7-2l-3.3-4.3l5-1.3l-2.7-4.6l4.6-0.6l-2.4-3.6l4.7-1 l-1-4.8l4.7,0.3l-1-5.4l4.8,1l-0.2-4.8l4.2,1.5l1-4.8l4,2.3l1.4-4.2l3.8,2.9l3.1-3.3l2,3.5l3.9-2.2l1,3.7l5.5-0.7l-0.9,3.7l5,1 l-1.5,3.1l4.6,1.2l-2.4,2.8l4.3,2.1l-2.9,2.2l3.4,2.8L1.4,274l2.7,3.1L1.6,279l2.3,2.9l-2.5,1.9l2.2,2.6l-2.8,1.8l1.6,3.4l-2.1,1.1 l-0.5,0.3l1.3,2.8c-10.4,5.6-27-3.7-27-3.7c-1.9,6-0.7,7.2,3.9,9.7c4.6,2.5,15.9,2.7,15.9,2.7l0.1,0.6l0.6,3.6L-9,308l-0.4,3.6 l-3.3-1.6l-2.7,3l-2.5-1.9l-3.5,2.7l-1.8-2.3l-2.6,2l-1.6-2.4l-2.9,2.4l-1.6-3.2l-3.5,1.6l-0.8-2.9l-3.7,1.1l0-0.8 c-6.8,4.4-19.7,9.8-28.9,11.7c-0.5,0.1-1,0.2-1.4,0.4l0,0.1l-0.1,0c-8.4,2.6-17.3,11.6-19.9,14.3c-0.7,0.8-2.3,2.7-4.4,5.2 c11.9,8.1,26.2,12.9,41.7,12.9c40.8,0,73.9-33.1,73.9-73.9C20.9,239.1-12.2,206-53,206"
			 />
		  <path fill="#FFFFFF"
			  d="M-71,264c0.5,2.8-0.8,4.5-3.7,5l-7.2,1.2l-0.6-3.9c-0.3-1.8,0.1-3.3,1.1-4.5c1-1.1,2.4-1.9,4.1-2.1 C-73.7,259.1-71.6,260.5-71,264 M-11.6,278.6c1-3.4-0.3-5.6-3.9-6.6c-1.6-0.4-3.2-0.4-4.6,0.2c-1.4,0.6-2.4,1.8-2.9,3.6l-1.1,3.8 l7,2C-14.2,282.4-12.4,281.4-11.6,278.6"
			  />
	  </svg>
  )
}
