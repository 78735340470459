import styled from 'styled-components'
import { device, DeviceSize } from '../../global.style'

export const CenterTopHeartComponentStyle = styled.div`
	height: 100vh;
	width: 100%;
  //background: url('/img/background1.webp') no-repeat center center;
  background-size: cover;
  display: flex;
  justify-content: center;
  flex-direction: column;
	align-items: center;
`

export const Heart = styled.div`
  background: url('/img/dessin.png') center center/100% no-repeat local;
	width: 350px;
	height: 350px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	
	@media screen and (${device(DeviceSize.tablet)}){
		width: 450px;
		height: 400px;
	}
	@media screen and (${device(DeviceSize.desktop)}){
		width: 650px;
		height: 600px;
	}
`

export const TextNames = styled.div`
	font-family: Great Vibes, Roboto, serif;
	color: #c79e69;
	font-size: 2.5rem;
  text-shadow: 0px 0px 2px rgba(255,255,255,1),
  	0px 0px 4px rgba(255,255,255,1),
  	0px 0px 8px rgba(255,255,255,1),
  	0px 0px 11px rgba(255,255,255,1),
  	0px 0px 11px rgba(255,255,255,1),
  	0px 0px 11px rgba(255,255,255,1),
  	0px 0px 11px rgba(255,255,255,1),
  	0px 0px 11px rgba(255,255,255,1),
  	0px 0px 11px rgba(255,255,255,1),
  	0px 0px 11px rgba(255,255,255,1),
  	0px 0px 12px rgba(255,255,255,1),
  	0px 0px 16px rgba(255,255,255,1),
  	0px 0px 20px rgba(255,255,255,1);

  @media screen and (${device(DeviceSize.tablet)}){
    font-size: 3rem;
  }
	
  @media screen and (${device(DeviceSize.desktop)}){
    font-size: 4rem;
  }
`
export const TextDate = styled.div`
	color: #c79e69;
	font-family: Roboto, serif;
	font-size: 1.4rem;
	font-weight: bold;
  text-shadow: 0px 0px 2px rgba(255,255,255,1),
  0px 0px 4px rgba(255,255,255,1),
  0px 0px 8px rgba(255,255,255,1),
  0px 0px 11px rgba(255,255,255,1),
  0px 0px 11px rgba(255,255,255,1),
  0px 0px 11px rgba(255,255,255,1),
  0px 0px 11px rgba(255,255,255,1),
  0px 0px 11px rgba(255,255,255,1),
  0px 0px 11px rgba(255,255,255,1),
  0px 0px 11px rgba(255,255,255,1),
  0px 0px 12px rgba(255,255,255,1),
  0px 0px 16px rgba(255,255,255,1),
  0px 0px 20px rgba(255,255,255,1);
`
