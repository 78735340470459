import React from 'react'
import {
	InformationsDetailStyle,
	LocalisationCartComponentStyle,
	LocalisationImage,
	LocalisationInformationStyle
} from './LocalisationCart.component.style'
import { Localisation } from '../../../types/Localisation.type'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface LocalisationCartComponentProps {
	localisation: Localisation
	order: number
}

export const LocalisationCartComponent: React.FC<LocalisationCartComponentProps> = ({ localisation, order }: LocalisationCartComponentProps) => {

  return (
  	<LocalisationCartComponentStyle inverse={order%2 === 0 }>
		  <LocalisationImage src={localisation.image}/>
		  <LocalisationInformationStyle>
			  <h3>{localisation.title}</h3>
			  <InformationsDetailStyle>
				  <div>
					  <FontAwesomeIcon icon="calendar-days" size="1x"/>
					  { localisation.time.format('DD/MM/YYYY HH[h]mm') }
				  </div>
				  <div>
					  <FontAwesomeIcon icon="location-dot" size="1x"/>
					  { localisation.address }
				  </div>
			  </InformationsDetailStyle>
		  </LocalisationInformationStyle>
	  </LocalisationCartComponentStyle>
  )
}
