import React from 'react'
import { HomePage } from './pages/Home/Home.page'
import './i18n'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faBars } from '@fortawesome/free-solid-svg-icons/faBars'
import { faLocationDot } from '@fortawesome/free-solid-svg-icons/faLocationDot'
import { faCalendarDays } from '@fortawesome/free-solid-svg-icons/faCalendarDays'
import { faGoogle } from '@fortawesome/free-brands-svg-icons/faGoogle'
import { faTrash} from '@fortawesome/free-solid-svg-icons/faTrash'
import { faUserPlus } from '@fortawesome/free-solid-svg-icons/faUserPlus'
import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons/faCircleQuestion'
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons/faCircleCheck'
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons/faCircleXmark'
import { faUtensils }  from '@fortawesome/free-solid-svg-icons/faUtensils'
import { faMartiniGlassCitrus }  from '@fortawesome/free-solid-svg-icons/faMartiniGlassCitrus'

import { Route, Routes } from 'react-router-dom'
import { ConfigurationPage } from './pages/Configuration/Configuration.page'
import {faMagnifyingGlass} from "@fortawesome/free-solid-svg-icons/faMagnifyingGlass";
import { faSeedling } from '@fortawesome/free-solid-svg-icons/faSeedling'

library.add(
	faGoogle,
	faBars,
	faLocationDot,
	faCalendarDays,
	faTrash,
	faUserPlus,
	faCircleQuestion,
	faCircleCheck,
	faCircleXmark,
	faUtensils,
	faMartiniGlassCitrus,
	faMagnifyingGlass,
	faSeedling
)

function App() {
	return (
		<Routes>
			<Route path="/" element={<HomePage />}/>
			<Route path="/configuration" element={<ConfigurationPage />} />
		</Routes>
	)
}

export default App
