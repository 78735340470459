import React from 'react'
import { CountersStyle, CounterStyleWithBorder, DayCounterComponentStyle, DayCounterTitle, DayCounterTitleWithBorder } from './DayCounter.component.style'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { useCounter } from '../../hooks/useCounter'

interface DayCounterComponentProps {}

export const DayCounterComponent: React.FC<DayCounterComponentProps> = (props: DayCounterComponentProps) => {
  const { t } = useTranslation()
	const weddingDate = dayjs('2022-09-17T15:00:00+02:00')
	const [ counter ] = useCounter()

	return (
  	<DayCounterComponentStyle>
		  <DayCounterTitle>
			  <DayCounterTitleWithBorder>
				  <div>{ t('counter.weAreWaiting')}</div>
				  <div>
					  { t('counter.bigDay')}
				  </div>
			  </DayCounterTitleWithBorder>
		  </DayCounterTitle>
		  <CountersStyle>
			  <CounterStyleWithBorder>
					<div>
						{ counter.day }
					</div>
				  <div>
					  {t('counter.days')}
				  </div>
			  </CounterStyleWithBorder>
			  <CounterStyleWithBorder>
					<div>
						{ counter.hour }
					</div>
				  <div>
					  {t('counter.hours')}
				  </div>
			  </CounterStyleWithBorder>
			  <CounterStyleWithBorder>
					<div>
						{ counter.minute }
					</div>
				  <div>
					  {t('counter.minutes')}
				  </div>
			  </CounterStyleWithBorder>
			  <CounterStyleWithBorder>
					<div>
						{ counter.second}
					</div>
				  <div>
					  {t('counter.seconds')}
				  </div>
			  </CounterStyleWithBorder>
		  </CountersStyle>
	  </DayCounterComponentStyle>
  )
}
