import styled from 'styled-components'

export const LeetchiButtonStyle = styled.a`
	display: flex;
	align-items: center;
	justify-content: center;
	background: #ff974b;
	padding: 15px 20px;
	border-radius: 5px;
	min-width: 200px;
	font-weight: bold;
	gap: 10px;
	cursor: pointer;
  text-decoration: none;
	color: var(--color-white);
	
	svg {
		width: 20px;
	}
`

export const ButtonText = styled.div`
  white-space: nowrap;
`
