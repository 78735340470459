import styled from 'styled-components'
import { ContentLayoutStyle } from '../../components/Layout/Content.layout.style'

export const GiftListComponentStyle = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
	margin-bottom: 50px;
`

export const GiftTitle = styled.h1`
	color: var(--color-primary);
	font-family: Great Vibes, Roboto, serif;
	font-size: 3rem;
	margin-bottom: 20px;
`

export const GiftDescription = styled(ContentLayoutStyle)`
  background-image:
          linear-gradient(to bottom, var(--color-primary-2), var(--color-primary-1)),
          url('img/tahiti.webp');
	height: 800px;
	color: var(--color-white);
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	gap: 30px;
	
	
	& > div {
		width: 80%;
		font-weight: bold;
		font-size: 1.5rem;
		text-align: center;
    & > img {
      margin-top: 20px;
    }
	}
`
export const PixValue = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 15px;
  margin-top: 20px;
	div {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 5px;
	}
	input {
		width: 75px;
		height: 25px;
		padding: 0 5px;
		border: none;
		border-radius: 5px;
	}
`

export const PixMessage = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 15px;
	margin-top: 20px;
	div {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 5px;
	}
	input {
		width: 200px;
		height: 25px;
		padding: 0 5px;
		border: none;
		border-radius: 5px;
	}
`
