import styled from 'styled-components'

export const InvitationsComponentStyle = styled.div``

export const InvitationList = styled.div`
	display: flex;
	padding: 15px 5px;
	gap: 5px;
	flex-wrap: wrap;
`

