import styled from 'styled-components'

export interface EngagementRingIconStyleProps{
	color?: string
}

export const EngagementRingIconStyle = styled.div<EngagementRingIconStyleProps>`
	width: 30px;
	fill: ${(props) => props.color ? `${props.color}`: 'white'}
`
