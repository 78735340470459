import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import translationFr from './translations/fr-FR.json'
import translationBr from './translations/pt-BR.json'


const resources = {
	fr: {
		translation: translationFr
	},
	pt: {
		translation: translationBr
	}
}

i18n
.use(initReactI18next)
.init({
	resources,
	lng:"fr-FR", //default language
	keySeparator: false,
	interpolation: {
		escapeValue: false,
	},
})

export default i18n


