import React from 'react'
import {
	LocalisationComponentStyle,
	LocalisationTitle
} from './Localisation.component.style'
import { useTranslation } from 'react-i18next'
import { LocalisationCartComponent } from './Cart/LocalisationCart.component'
import { Localisation } from '../../types/Localisation.type'
import dayjs from 'dayjs'

interface LocalisationComponentProps {}

export const LocalisationComponent: React.FC<LocalisationComponentProps> = (props: LocalisationComponentProps) => {
	const { t } = useTranslation()

	const localisations: Array<Localisation> = [
		{
			title: t('localisation.ceremonie_civil'),
			address: '555 Rte de Boissise, 77350 Le Mée-sur-Seine, France',
			time: dayjs('2022-09-17T15:00:00+02:00'),
			image: '/img/mairie.webp',
			link: ''
		},
		{
			title: t('localisation.ceremonie_laique'),
			address: 'Rue de Paris, 77163 Mortcerf, France',
			time: dayjs('2022-09-17T17:00:00+02:00'),
			image: '/img/laique.webp',
			link: ''
		},
		{
			title: t('localisation.cocktail'),
			address: 'Rue de Paris, 77163 Mortcerf, France',
			time: dayjs('2022-09-17T18:00:00+02:00'),
			image: '/img/cocktail.webp',
			link: ''
		},
		{
			title: t('localisation.diner_soiree'),
			address: 'Rue de Paris, 77163 Mortcerf, France',
			time: dayjs('2022-09-17T20:00:00+02:00'),
			image: '/img/repas-fete.webp',
			link: ''
		}
	]

  return (
  	<LocalisationComponentStyle id="localisation">
		  <LocalisationTitle>
				{t('localisation.title')}
			</LocalisationTitle>
		  {
			  localisations.map((localisation, index)=> (
				  <LocalisationCartComponent localisation={localisation} order={index}/>
			  ))
		  }
	  </LocalisationComponentStyle>
  )
}
