import React from 'react'
import { HeaderComponentStyle, HeaderContent, HeaderLinkItem, HeaderLinkItems, Logo } from './Header.component.style'

import { EngagementRingIcon } from '../icons/EngagementRing/EngagementRing.icon'
import { MobileMenuComponent } from '../Menu/MobileMenu.component'
import { LanguageComponent } from '../Language/Language.component'
import { HiddenChangeHeaderComponent } from './HiddenChangeHeader.component'
import { useInView } from 'react-intersection-observer'
import { useTranslation } from 'react-i18next'


interface HeaderComponentProps {}

export const HeaderComponent: React.FC<HeaderComponentProps> = (props: HeaderComponentProps) => {
  const {ref, inView} = useInView()
  const { t } = useTranslation()
  return (
    <>
      <HiddenChangeHeaderComponent refScroll={ref}/>
      <HeaderComponentStyle transparent={inView}>
        <HeaderContent>
          <Logo>Ivy <EngagementRingIcon /> Florian</Logo>
          <MobileMenuComponent />
          <HeaderLinkItems>
            <HeaderLinkItem href="#localisation">
              {t('localisation.title')}
            </HeaderLinkItem>
            <HeaderLinkItem href="#rsvp">
              {t('rsvp.title')}
            </HeaderLinkItem>
            <HeaderLinkItem href="#gift">
              {t('gift.title-menu')}
            </HeaderLinkItem>
            <LanguageComponent header />
          </HeaderLinkItems>
        </HeaderContent>
      </HeaderComponentStyle>
    </>
  )
}
